import axios from "axios";
import { Buffer } from "buffer";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_Link;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: `Basic ${token}`,
  "Access-Control-Allow-Origin": API_ORIGIN,
};

const headers2 = {
  "Content-Type": "application/json",
  Authorization: `Basic ${token}`,
  "Access-Control-Allow-Origin": API_ORIGIN,
};

const loginCustomer = (values) => {
  return axios.post(
    API_URL + "customer-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const loginOsr = (values) => {
  return axios.post(
    API_URL + "osr-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const loginPavlina = (values) => {
  return axios.post(
    API_URL + "pavlina-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const loginAccountant = (values) => {
  return axios.post(
    API_URL + "accountant-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const loginAdmin = (values) => {
  return axios.post(
    API_URL + "admin-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const profileData = (id, apiLink) => {
  return axios.get(API_URL + apiLink + id, {
    headers: headers,
  });
};

const profileUpdate = (values, id, apiLink) => {
  return axios.put(
    API_URL + apiLink + id,
    {
      email: values.email,
      username: values.name,
      currentPassword: values.passwordOld,
      newPassword: values.password,
      confirmPassword: values.passwordNew,
    },
    {
      headers: headers2,
    }
  );
};

// add customer apis call

const addCustomer = (values, adminId, customerAccessToken) => {
  return axios.post(
    API_URL + "add-customer-data",
    {
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      denomination: values.denomination,
      AdminId: adminId,
      sessionId: customerAccessToken,
    },
    { headers: headers }
  );
};

const uploadCustomers = (formData) => {
  return axios.post(API_URL + "import-customer-data", formData, {
    headers: headers,
  });
};

const getStates = () => {
  return axios.get(API_URL + "getStates", {
    params: {},
    headers: headers,
  });
};

const getCustomersData = (id, pageNo) => {
  return axios.get(API_URL + "show-all-customers", {
    params: {
      AdminId: id,
      page: pageNo,
    },

    headers: headers,
  });
};
const getCustomersDataById = (id) => {
  return axios.get(API_URL + "get-customer-id/" + id, {
    headers: headers,
  });
};

const updateCustomer = (values, adminId, EditId, accessToken) => {
  return axios.put(
    API_URL + "update-customer-data/" + EditId,
    {
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      denomination: values.denomination,
      AdminId: adminId,
      sessionId: accessToken,
    },
    { headers: headers2 }
  );
};

const deleteCustomersData = (id) => {
  return axios.delete(API_URL + "customer-id/" + id, {
    headers: headers2,
  });
};

const deleteCustomersDataAll = (id) => {
  return axios.delete(API_URL + "del-all-customer/" + id, {
    headers: headers2,
  });
};

const deleteSelectedCustomersData = (id, customerSiteId) => {
  return axios.delete(API_URL + "selected-customer", {
    headers: headers2,
    data: {
      CheckId: id,
      SiteId: customerSiteId,
    },
  });
};

const checkCustomerEmail = (email) => {
  return axios.get(
    API_URL + "duplicate-email/" + email,

    { headers: headers }
  );
};
const updateCustomerEmail = (email, editId) => {
  return axios.get(
    API_URL + "duplicate-profile-email/" + editId + "/" + email,
    { headers: headers }
  );
};

const getDataForSubmit = (id) => {
  return axios.get(API_URL + "customer-data-popup/" + id, {
    headers: headers,
  });
};

const submitFinalCustomers = (adminId, siteId) => {
  return axios.post(
    API_URL + "submit-customer-data",
    { AdminId: adminId, SiteId: siteId },
    {
      headers: headers,
    }
  );
};

// calls for oser panels
const getOsrData = () => {
  return axios.get(API_URL + "show-company-data-osr", {
    headers: headers,
  });
};
const viewCustomerInModel = (gpNo, pageNo) => {
  return axios.get(API_URL + "view-by-company-data-osr", {
    params: {
      groupNo: gpNo,
      page: pageNo,
    },

    headers: headers,
  });
};

const getOsrCustomerData = (id) => {
  return axios.get(API_URL + "customer-data-osr/" + id, {
    headers: headers,
  });
};

const deleteOsrMain = (id) => {
  return axios.delete(API_URL + "company-by-id-osr", {
    headers: headers2,
    data: {
      groupNo: id,
    },
  });
};

const deleteSingleOsrCustomer = (id) => {
  return axios.delete(API_URL + "customer-by-id-osr/" + id, {
    headers: headers2,
  });
};

const updateCustomerOsr = (values, osrId, editId) => {
  return axios.put(
    API_URL + "customer-data-osr/" + editId,
    {
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      denomination: values.denomination,
      OsrId: osrId,
    },
    { headers: headers2 }
  );
};

const checkOsrCustomerEmail = (email, editId) => {
  return axios.get(API_URL + "duplicate-email/" + editId + "/" + email, {
    headers: headers,
  });
};

const checkJobNumber = (jobNo) => {
  return axios.get(API_URL + "exist-job/" + jobNo, {
    headers: headers2,
  });
};

const showLastUploadCards = (id) => {
  return axios.get(API_URL + "show-old-card-popup/" + id, {
    headers: headers2,
  });
};

const lastUploadSave = (gropNo, osr_id, jobNo, siteId, values) => {
  return axios.post(
    API_URL + "add-old-card/" + siteId,
    {
      osrId: osr_id,
      jobNo: jobNo,
      groupNo: gropNo,
      start_date: moment(values.redSdate).format("MM/DD/YYYY"),
      end_date: moment(values.redEdate).format("MM/DD/YYYY"),
      site: API_ORIGIN?.replace("https://", ""),
    },

    {
      headers: headers,
    }
  );
};

const getAdminCards = () => {
  return axios.get(API_URL + "admin-cards", {
    headers: headers2,
  });
};

const finalAddJob = (values, gropNo, osr_id, jobNo) => {
  return axios.post(
    API_URL + "new-job-cards",
    {
      start_date: moment(values.redSdate).format("MM/DD/YYYY"),
      end_date: moment(values.redEdate).format("MM/DD/YYYY"),
      osrId: osr_id,
      jobNo: jobNo,
      groupNo: gropNo,
      site: API_ORIGIN?.replace("https://", ""),
      card:
        values.card1 +
        "," +
        values.card2 +
        "," +
        values.card3 +
        "," +
        values.card4 +
        "," +
        values.card5 +
        "," +
        values.card6 +
        "," +
        values.card7 +
        "," +
        values.card8 +
        "," +
        values.card9 +
        "," +
        values.card10 +
        "," +
        values.card11 +
        "," +
        values.card12,
    },

    {
      headers: headers,
    }
  );
};

// const addDateForExistJob = (values, gropNo, osr_id, jobNo) => {
//   return axios.post(
//     API_URL + "exist-job-date ",
//     {
//       start_date: moment(values.redSdate).format("MM/DD/YYYY"),
//       end_date: moment(values.redEdate).format("MM/DD/YYYY"),
//       groupNo: gropNo,
//     },

//     {
//       headers: headers,
//     }
//   );
// };
const addDateForExistJob = (values, gropNo, osr_id, jobNo) => {
  return axios.post(
    API_URL + "add-job-date/" + jobNo,
    {
      start_date: moment(values.redSdate).format("MM/DD/YYYY"),
      end_date: moment(values.redEdate).format("MM/DD/YYYY"),
      groupNo: gropNo,
    },

    {
      headers: headers,
    }
  );
};

const existJobRetain = (gropNo, osr_id, jobNo) => {
  return axios.post(
    API_URL + "exist-job-old-cards",
    {
      osrId: osr_id,
      jobNo: jobNo,
      groupNo: gropNo,
      site: API_ORIGIN?.replace("https://", ""),
    },

    {
      headers: headers,
    }
  );
};

const jobSubmit = (gropNo, osr_id, jobNo) => {
  return axios.post(
    API_URL + "submit-job",
    {
      osrId: osr_id,
      jobNo: jobNo,
      groupNo: gropNo,
    },

    {
      headers: headers,
    }
  );
};

const getOsrHistroyData = () => {
  return axios.get(API_URL + "company-history-data", {
    headers: headers,
  });
};
const viewOsrHistroyInModel = (gpNo, pageNo, limit) => {
  return axios.get(
    API_URL +
      "company-history-detail/" +
      gpNo +
      "?page=" +
      pageNo +
      "?limit=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const mailledElectronically = (gpNo, status) => {
  return axios.put(
    API_URL + "job-email",
    { groupNo: gpNo, status: status },
    { headers: headers2 }
  );
};

const cardAddForExistJob = (values, gropNo, osr_id, jobNo) => {
  return axios.post(
    API_URL + "exist-job-new-cards",
    {
      osrId: osr_id,
      jobNo: jobNo,
      groupNo: gropNo,
      site: API_ORIGIN?.replace("https://", ""),
      card:
        values.card1 +
        "," +
        values.card2 +
        "," +
        values.card3 +
        "," +
        values.card4 +
        "," +
        values.card5 +
        "," +
        values.card6 +
        "," +
        values.card7 +
        "," +
        values.card8 +
        "," +
        values.card9 +
        "," +
        values.card10 +
        "," +
        values.card11 +
        "," +
        values.card12,
    },

    {
      headers: headers,
    }
  );
};

// accountant section api calls

const getAccountantData = () => {
  return axios.get(API_URL + "company-data", {
    headers: headers,
  });
};

const deleteAccountantMain = (gpNo) => {
  return axios.delete(API_URL + "company-data/" + gpNo, {
    headers: headers2,
  });
};

const accountantSubmit = (gropNo, id) => {
  return axios.post(
    API_URL + "submit-voucher",
    {
      accountant: id,
      group: gropNo,
      site: API_ORIGIN?.replace("https://", ""),
    },

    {
      headers: headers,
    }
  );
};

const voucherClear = (gpNo) => {
  return axios.put(
    API_URL + "clear-voucher/" + gpNo,
    {},
    {
      headers: headers,
    }
  );
};
const voucherUpdate = (voucher, uId, accId) => {
  return axios.post(
    API_URL + "voucher",
    {
      voucher: voucher,
      user: uId,
      accountant: accId,
    },
    {
      headers: headers,
    }
  );
};

const invoiceClear = (gpNo) => {
  return axios.put(
    API_URL + "clear-invoice/" + gpNo,
    {},
    {
      headers: headers,
    }
  );
};

const invoiceUpdate = (invoiceNo, uId, accId, gpNo) => {
  return axios.post(
    API_URL + "invoice",
    {
      invoice: invoiceNo,
      user: uId,
      group: gpNo,
      accountant: accId,
    },
    {
      headers: headers,
    }
  );
};

const costUpdate = (voucher, uId, accId) => {
  return axios.post(
    API_URL + "cost",
    {
      cost: voucher,
      user: uId,
      accountant: accId,
    },
    {
      headers: headers,
    }
  );
};

// pavlina section api calls

const searchCertRecord = (values, pageNo) => {
  return axios.get(
    API_URL +
      "search-record?page=" +
      pageNo +
      "&certificate=&voucher=" +
      values.vouchNo +
      "&begin=" +
      values.redemSt +
      "&end=" +
      values.redemEd +
      "&job=" +
      values.jobNo +
      "&cardType=" +
      values.cardType +
      "&phone=" +
      values.phone +
      "&certStatus=" +
      values.certiStatus +
      "&customer=" +
      values.custName +
      "&firstName=" +
      values.fName +
      "&lastName=" +
      values.lName +
      "&address=" +
      values.address +
      "&city=" +
      values.city +
      "&state=" +
      values.state +
      "&zip=" +
      values.zipCode +
      "&denomination=" +
      values.denom +
      "&receiveMethod=" +
      values.recMeth +
      "&mailDate=" +
      values.statusDate +
      "&cardOrdered=&fileNote=" +
      "&code=" +
      values.prdType +
      "&certNumber1=" +
      values.certiNo +
      "&uniqueId=&emailAddress=" +
      values.email +
      "&paNumber=" +
      values.paNo +
      "&invoiceNo=" +
      values.invcNo +
      "&clientCost=" +
      values.clCost +
      "&redeemDate=" +
      values.RedeemedOnDate +
      "&random_certificate=" +
      values.randNo,
    {
      headers: headers,
    }
  );
};

const searchCustomRecord = (key, val) => {
  return axios.get(API_URL + "custom-search-record/" + key + "/" + val, {
    headers: headers,
  });
};

const allRecordChange = (pavId, status, idz) => {
  return axios.put(
    API_URL + "certificates",
    {
      pavlina: pavId,
      status: status,
      idz: idz,
    },
    {
      headers: headers2,
    }
  );
};

const deActivateVoucher = (id) => {
  return axios.put(
    API_URL + "voucher-status/" + id,
    {},
    {
      headers: headers2,
    }
  );
};

const checkJobData = (jobNo) => {
  return axios.get(API_URL + "used-certificates/" + jobNo, {
    headers: headers,
  });
};

const multiJobSearch = (job, pageNo) => {
  // let query = `multi-job?page=${pageNo}&certificate=${certificate}&voucher=${voucher}&begin=${begin}&end=${end}&job=${job}&cardType=${cardType}&phone=${phone}&certStatus=${certStatus}&customer=${customer}&firstName=${firstName}&lastName=${lastName}&address=${address}&city=${city}&state=${state}&zip=${zip}&denomination=${denomination}&receiveMethod=${receiveMethod}&mailDate=${mailDate}&cardOrdered=${cardOrdered}&fileNote=${fileNote}&code=${code}&certNumber1=${certNumber1}&uniqueId=${uniqueId}&emailAddress=${emailAddress}&paNumber=${paNumber}&invoiceNo=${invoiceNo}&clientCost=${clientCost}&redeemDate=${redeemDate}&random_certificate=${random_certificate}`;
  return axios.get(API_URL + `multi-job?page=${pageNo}&job=${job}`, {
    headers: headers2,
  });
};

const toBeMailedCard = (pageNo) => {
  return axios.get(API_URL + "mailed-cards?page=" + pageNo, {
    headers: headers2,
  });
};
const cardVirtual = (pageNo) => {
  return axios.get(API_URL + "virtual-cards?page=" + pageNo, {
    headers: headers2,
  });
};

const getCompanyList = () => {
  return axios.get(API_URL + "company-list", {
    headers: headers2,
  });
};
const mergeMailSearch = (company, card, pageNo) => {
  return axios.get(
    API_URL +
      "merged-mail/company?page=" +
      pageNo +
      "&card=" +
      card +
      "&echoice=" +
      company,
    {
      headers: headers2,
    }
  );
};

const getForEdit = (id) => {
  return axios.get(
    API_URL + "certificate/" + id,

    {
      headers: headers2,
    }
  );
};

const updateCerti = (id, values, uId) => {
  return axios.put(
    API_URL + "certificate/" + uId,
    {
      pavId: id,
      pavName: "Pavlina",
      certNumber1: values.certiNo,
      customer: values.custName,
      clientCost: values.clCost,
      random_certificate: values.randNo,
      voucher: values.vouchNo,
      job: values.jobNo,
      redeemDate: moment(values.RedeemedOnDate).format("MM/DD/YYYY"),
      mailDate: moment("0000-00-00").format("MM/DD/YYYY"),
      firstName: values.fName,
      lastName: values.lName,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      phone: values.phone,
      emailAddress: values.email,
      cardType: values.cardType,
      certStatus: values.certiStatus,
      statusDate: values.statusDate,
      receiveMethod: values.recMeth,

      denomination: values.denom,
      paNumber: values.paNo,
      invoiceNo: values.invcNo,
    },
    {
      headers: headers2,
    }
  );
};

// super admin api calls

const listCustomer = (pageNo) => {
  return axios.get(
    API_URL + "list-customer?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const deleteSiteSingle = (id) => {
  return axios.delete(API_URL + "site/" + id, {
    headers: headers2,
  });
};

const siteCustomerAdd = (formData) => {
  return axios.post(API_URL + "site", formData, {
    headers: headers,
  });
};

const listCustomerById = (id) => {
  return axios.get(API_URL + "list-site/" + id, {
    headers: headers,
  });
};

const siteCustomerUpdate = (formData, id) => {
  return axios.post(API_URL + "site/" + id, formData, {
    headers: headers,
  });
};

const listSubCompany = (pageNo) => {
  return axios.get(
    API_URL + "list-subcompany?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const subCompanyAdd = (formData) => {
  return axios.post(API_URL + "sub-company", formData, {
    headers: headers,
  });
};

const subCompanyUpdate = (formData, id) => {
  return axios.post(API_URL + "sub-company/" + id, formData, {
    headers: headers,
  });
};

const listSubCompanyById = (id) => {
  return axios.get(API_URL + "sub-company/" + id, {
    headers: headers,
  });
};

const deleteSubCompany = (id) => {
  return axios.delete(API_URL + "sub-company/" + id, {
    headers: headers2,
  });
};

const parentSites = () => {
  return axios.get(
    API_URL + "list-sites",

    {
      headers: headers2,
    }
  );
};

const listPlasticManage = (pageNo) => {
  return axios.get(
    API_URL + "list-cityfurniture?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const sitePlasticAdd = (values, adminId) => {
  return axios.post(
    API_URL + "pyp-site",
    {
      emailSubject: values.subject,
      emailBody: values.body,
      username: values.userName,
      email: values.email,
      password: values.password,
      siteName: values.siteName,
      naVisio: values.visio,
      AdminId: adminId,
      productType: values.pedType,
    },
    {
      headers: headers2,
    }
  );
};

const sitePlasticUpdate = (id, values, adminId) => {
  return axios.put(
    API_URL + "pyp-site/" + id,
    {
      AdmId: values.id,
      emailSubject: values.subject,
      emailBody: values.body,
      username: values.userName,
      email: values.email,
      password: values.password,
      siteName: values.siteName,
      naVisio: values.visio,
      admin_id: adminId,
      productType: values.pedType,
    },
    {
      headers: headers2,
    }
  );
};

const listPlasticById = (id) => {
  return axios.get(API_URL + "pyp-site/" + id, {
    headers: headers,
  });
};

const deleteSitePlastic = (id) => {
  return axios.delete(API_URL + "cityfurniture/" + id, {
    headers: headers2,
  });
};

const listOsr = (pageNo) => {
  return axios.get(
    API_URL + "list-osr?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const osrUserAdd = (values, adminId) => {
  return axios.post(
    API_URL + "osr",
    {
      admin_id: adminId,
      username: values.userName,
      password: values.password,
      OsrName: values.osrName,
      OsrEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const osrUserUpdate = (values, adminId) => {
  return axios.put(
    API_URL + "osr/" + values.id,
    {
      admin_id: adminId,
      username: values.userName,
      password: values.password,
      OsrName: values.osrName,
      OsrEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const deleteOsr = (id) => {
  return axios.delete(API_URL + "osr/" + id, {
    headers: headers2,
  });
};

const deletSelectedeOsr = (idz) => {
  return axios.delete(API_URL + "selected-osr", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listOsrById = (id) => {
  return axios.get(API_URL + "osr/" + id, {
    headers: headers,
  });
};

const listAccountant = (pageNo) => {
  return axios.get(
    API_URL + "accountant?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const accountantUserAdd = (values, adminId) => {
  return axios.post(
    API_URL + "accountant",
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      AccName: values.name,
      AccEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};
const accountantUserUpdate = (values, adminId) => {
  return axios.put(
    API_URL + "accountant/" + values.id,
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      AccName: values.name,
      AccEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const listAccountantById = (id) => {
  return axios.get(API_URL + "accountant/" + id, {
    headers: headers,
  });
};

const deleteAcc = (id) => {
  return axios.delete(API_URL + "accountant/" + id, {
    headers: headers2,
  });
};

const deletSelectedAcc = (idz) => {
  return axios.delete(API_URL + "selected-accountant", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listCsr = (pageNo) => {
  return axios.get(
    API_URL + "csr?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const csrUserAdd = (values, adminId) => {
  return axios.post(
    API_URL + "csr",
    {
      admin_id: adminId,
      username: values.userName,
      password: values.password,
      CsrName: values.name,
      CsrEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const csrUserUpdate = (values, adminId) => {
  return axios.put(
    API_URL + "csr/" + values.id,
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      CsrName: values.name,
      CsrEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const deleteCsr = (id) => {
  return axios.delete(API_URL + "csr/" + id, {
    headers: headers2,
  });
};

const deletSelectedCsr = (idz) => {
  return axios.delete(API_URL + "selected-csr", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listCsrById = (id) => {
  return axios.get(API_URL + "csr/" + id, {
    headers: headers,
  });
};

const listPavlina = (pageNo) => {
  return axios.get(
    API_URL + "pavlina?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const pavlinaAdminAdd = (values, adminId) => {
  return axios.post(
    API_URL + "pavlina",
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      AdminName: values.name,
      AdminEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const pavlinaAdminUpdated = (values, adminId) => {
  return axios.put(
    API_URL + "pavlina/" + values.id,
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      AdminName: values.name,
      AdminEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const deletePav = (id) => {
  return axios.delete(API_URL + "pavlina/" + id, {
    headers: headers2,
  });
};

const deletSelectedPav = (idz) => {
  return axios.delete(API_URL + "selected-pavlina", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listPavById = (id) => {
  return axios.get(API_URL + "pavlina/" + id, {
    headers: headers,
  });
};

const lockupTable = (pageNo) => {
  return axios.get(
    API_URL +
      "certificates/" +
      API_ORIGIN?.replace("https://", "") +
      "?page=" +
      pageNo,

    {
      headers: headers2,
    }
  );
};

const searchLockupTable = (key, val) => {
  return axios.get(
    API_URL +
      "search-certificates" +
      API_ORIGIN?.replace("https://", "/") +
      "/" +
      key +
      "/" +
      val,
    {
      headers: headers,
    }
  );
};

const lockupRecordById = (id) => {
  return axios.get(
    API_URL + "certificates/" + id + API_ORIGIN?.replace("https://", "/"),
    {
      headers: headers,
    }
  );
};

const lockupUpdateById = (values, uId) => {
  return axios.put(
    API_URL + "certificates/" + uId,
    {
      certNumber1: values.certiNo,
      realcerti: values.certiNo,
      redemptEnd: moment(values.redemEd).format("MM-DD-YYYY"),
      redemptBegins: moment(values.redemSt).format("MM-DD-YYYY"),

      customer: values.custName,
      jobNumber: values.jobNo,

      firstname: values.fName,
      lastname: values.lName,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      phone: values.phone,
      email: values.email,
      receivedMethod: values.recMeth,
      cardType: values.cardType,
      certiStatus: values.certiStatus,
      statusDate: moment(values.statusDate).format("MM-DD-YYYY"),
      receiveMethod: values.recMeth,

      denomination: values.denom,
    },
    {
      headers: headers2,
    }
  );
};

const listCard = (pageNo, val) => {
  return axios.get(
    API_URL + "cards?page=" + pageNo + "&CardName=" + val,

    {
      headers: headers2,
    }
  );
};
const listCardById = (id) => {
  return axios.get(
    API_URL + `cards/${id}`,

    {
      headers: headers2,
    }
  );
};

const addCard = (formData) => {
  return axios.post(API_URL + "card", formData, {
    headers: headers,
  });
};
const updateCard = (formData, id) => {
  return axios.post(API_URL + "update-cards/" + id, formData, {
    headers: headers,
  });
};

const searchCard = (val) => {
  if (val !== "") {
    return axios.get(API_URL + "cards?CardName=" + val, {
      headers: headers,
    });
  } else {
    return axios.get(API_URL + "cards?CardName=", {
      headers: headers,
    });
  }
};

const deletSelectedCard = (idz) => {
  return axios.delete(API_URL + "selected-cards", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listAdmin = (pageNo) => {
  return axios.get(
    API_URL + "admin?page=" + pageNo,

    {
      headers: headers2,
    }
  );
};

const listAdminById = (id) => {
  return axios.get(API_URL + "admin/" + id, {
    headers: headers,
  });
};

const adminAdd = (values, adminId) => {
  return axios.post(
    API_URL + "admin",
    {
      admin_id: adminId,
      username: values.userName,
      password: values.password,
      AdminName: values.name,
      AdminEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const adminUpdated = (values, adminId) => {
  return axios.put(
    API_URL + "admin/" + values.id,
    {
      adminId: adminId,
      username: values.userName,
      password: values.password,
      AdminName: values.name,
      AdminEmail: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const deleteAdmin = (id) => {
  return axios.delete(API_URL + "admin/" + id, {
    headers: headers2,
  });
};

const deletSelectedAdmin = (idz) => {
  return axios.delete(API_URL + "selected-admin", {
    headers: headers2,
    data: {
      AdminIds: idz,
    },
  });
};

const listCardDetail = (pageNo) => {
  return axios.get(API_URL + "certificate-detail?page=" + pageNo, {
    headers: headers2,
  });
};

const cardDetailByStatusCompany = (status, company, pageNo) => {
  let newStatus;
  if (status === "voucherMailed") {
    newStatus = "Voucher Mailed";
  } else if (status === "voucherRecieved") {
    newStatus = "Voucher Recieved";
  } else if (status === "cardOrdered") {
    newStatus = "Card Ordered";
  } else if (status === "cardMailed") {
    newStatus = "Card Mailed";
  } else if (status === "expired") {
    newStatus = "Expired";
  } else if (status === "void") {
    newStatus = "Void";
  } else {
    newStatus = status;
  }
  return axios.get(
    API_URL +
      "certificate-detail/" +
      newStatus +
      "/" +
      company +
      "?page=" +
      pageNo,
    {
      headers: headers2,
    }
  );
};

const listMails = (limit, pageNo) => {
  return axios.get(
    API_URL +
      `list-mails${API_ORIGIN?.replace(
        "https://",
        "/"
      )}?page=${pageNo}&limit=${limit}`,

    {
      headers: headers2,
    }
  );
};

const userForMail = (pageNo, groupNo) => {
  return axios.get(
    API_URL + `users-for-mail/${groupNo}?page=${pageNo}`,

    {
      headers: headers2,
    }
  );
};

const sendMailById = (userId) => {
  return axios.get(
    API_URL + `send-mail/${userId}`,

    {
      headers: headers2,
    }
  );
};
const sendMailToSelected = (ids) => {
  return axios.post(
    API_URL + `send-selected-mail`,
    {
      ids: ids,
    },
    {
      headers: headers2,
    }
  );
};

const sendMailByGroup = (groupNo) => {
  return axios.get(
    API_URL + `send-all-mail/${groupNo}`,

    {
      headers: headers2,
    }
  );
};

const listStates = (limit, pageNo) => {
  return axios.get(
    API_URL + `states?page=${pageNo}&limit=${limit}`,

    {
      headers: headers2,
    }
  );
};

const listStatesById = (id) => {
  return axios.get(API_URL + "state/" + id, {
    headers: headers,
  });
};

const addStates = (values) => {
  return axios.post(
    API_URL + "state",
    {
      state: values.name,
      code: values.code,
    },
    {
      headers: headers2,
    }
  );
};

const updatedStates = (values, id) => {
  return axios.put(
    API_URL + "state/" + id,
    {
      state: values.name,
      code: values.code,
    },
    {
      headers: headers2,
    }
  );
};

const deleteState = (id) => {
  return axios.delete(API_URL + "state/" + id, {
    headers: headers2,
  });
};

const deletSelectedStates = (idz) => {
  return axios.delete(API_URL + "multiple-states", {
    headers: headers2,
    data: {
      ids: idz,
    },
  });
};

const listCardByCompany = (pageNo) => {
  return axios.get(
    API_URL +
      `list-cards-by-customer${API_ORIGIN?.replace(
        "https://",
        "/"
      )}?page=${pageNo}`,

    {
      headers: headers2,
    }
  );
};

const listCardByJob = (pageNo) => {
  return axios.get(
    API_URL +
      `list-cards-by-job${API_ORIGIN?.replace("https://", "/")}?page=${pageNo}`,

    {
      headers: headers2,
    }
  );
};

const getCardByJobGroup = (job, group) => {
  return axios.get(
    API_URL + `list-cards-by-job-group/${job}/${group}`,

    {
      headers: headers2,
    }
  );
};

const updateCardByJobGroup = (job, cards, adminId, group) => {
  return axios.put(
    API_URL + "cards-by-job-group/" + job,
    {
      job: job,
      admin: adminId,
      // site: API_ORIGIN.replace("https://", ""),
      cardIds: cards,
      group: group,
    },
    {
      headers: headers2,
    }
  );
};

const getAllCard = () => {
  return axios.get(
    API_URL + `all-cards`,

    {
      headers: headers2,
    }
  );
};

const jobList = (pageNo) => {
  return axios.get(
    API_URL +
      `list-jobs` +
      API_ORIGIN?.replace("https://", "/") +
      "?page=" +
      pageNo,
    {
      headers: headers2,
    }
  );
};
const jobDetail = (job, group) => {
  return axios.get(API_URL + `detail-job-by-group/` + job + "/" + group, {
    headers: headers2,
  });
};

const jobByGroup = (groupNo) => {
  return axios.get(API_URL + "job-data/" + groupNo, {
    headers: headers,
  });
};

const deletJobByGroup = (idz) => {
  return axios.delete(API_URL + "job-by-group", {
    headers: headers2,
    data: {
      groups: idz,
      site: API_ORIGIN?.replace("https://", ""),
    },
  });
};

const jobAdd = (job, cards, adminId) => {
  return axios.post(
    API_URL + "add-job",
    {
      job: job,
      admin: adminId,
      site: API_ORIGIN?.replace("https://", ""),
      cards: cards,
    },
    {
      headers: headers2,
    }
  );
};
const jobUpdate = (groupNo, job, cards, adminId, id) => {
  return axios.put(
    API_URL + "job-w-group",
    {
      job: job,
      group: groupNo,
      admin: adminId,
      site: API_ORIGIN?.replace("https://", ""),
      cardIds: cards,
      id: id,
    },
    {
      headers: headers2,
    }
  );
};

const allSites = () => {
  return axios.get(API_URL + "all-sites", {
    headers: headers,
  });
};

const allSitesCertificatesStatus = (status) => {
  return axios.put(
    API_URL + "all-sites-certificates/" + status,
    {},
    {
      headers: headers,
    }
  );
};

const siteStatusUpdate = (status, id) => {
  return axios.put(
    API_URL + "site-random-certificates",
    {
      status: status,
      id: id,
    },
    {
      headers: headers2,
    }
  );
};

const updateRandomCertificates = (values) => {
  return axios.put(
    API_URL + "random-certificates",
    {
      length: values.certiLength,
      upper_case: values.Uppercase ? "ABCDEFGHIJKLMNOPQRSTUVWXYZ" : "",
      lower_case: values.Lowercase ? "abcdefghijklmnopqrstuvwxyz" : "",
      digits: values.Digits ? "0123456789" : "",
      special_chracter: values.Special ? "`~@#$%^&*+|/" : "",
      punctuation: values.Punctuation ? "!?;:,`_." : "",
      brackets: values.Brackets ? "(){}[]<>" : "",
      prefix: values.Prefix,
    },
    {
      headers: headers2,
    }
  );
};

const listDeletedUser = (pageNo, limit) => {
  return axios.get(
    API_URL +
      "users-by-group" +
      API_ORIGIN?.replace("https://", "/") +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,

    {
      headers: headers2,
    }
  );
};

const deleteDeletedUser = (id) => {
  return axios.delete(API_URL + "users-by-group/" + id, {
    headers: headers2,
  });
};

const certiSearch = (values) => {
  return axios.get(
    API_URL +
      `certificate/${values.certiNo}${API_ORIGIN?.replace("https://", "/")}`,

    {
      headers: headers2,
    }
  );
};

// 3 in 1 steps api from here
const updateCustomerCheckDuplicateVoucher = (voucher, editId) => {
  return axios.get(
    API_URL + "check-customer-update-voucher/" + voucher + "/" + editId,
    { headers: headers }
  );
};
const updateOsrCheckDuplicateVoucher = (voucher, editId, group) => {
  return axios.get(
    API_URL +
      "check-osr-update-voucher/" +
      voucher +
      "/" +
      editId +
      "/" +
      group,
    { headers: headers }
  );
};

const updateAccountantCheckDuplicateVoucher = (voucher, editId, group) => {
  return axios.get(
    API_URL +
      "check-accountant-update-voucher/" +
      voucher +
      "/" +
      editId +
      "/" +
      group,
    { headers: headers }
  );
};
const checkDuplicateVoucher = (voucher) => {
  return axios.get(
    API_URL + "check-duplicate-voucher/" + voucher,

    { headers: headers }
  );
};
const deleteCustomersDataNew = (id, type) => {
  return axios.delete(API_URL + "del-customer-id-new/" + id + "/" + type, {
    headers: headers2,
  });
};

const addCustomerNew = (values, adminId, customerAccessToken) => {
  return axios.post(
    API_URL + "add-customer-new",
    {
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      denomination: values.denomination,
      voucher: values.voucher,
      AdminId: adminId,
      sessionId: customerAccessToken,
    },
    { headers: headers }
  );
};
const getCustomersDataByIdNew = (id, type) => {
  return axios.get(API_URL + "get-customer-id-new/" + id + "/" + type, {
    headers: headers,
  });
};
const updateCustomerNew = (values, adminId, EditId, accessToken, EditType) => {
  return axios.put(
    API_URL + "update-customer-new/" + EditId + "/" + EditType,
    {
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      denomination: values.denomination,
      AdminId: adminId,
      voucher: values.voucher,
      sessionId: accessToken,
    },
    { headers: headers2 }
  );
};
const showLastUploadCardsForJob = (job) => {
  return axios.get(API_URL + "show-exist-job-cards/" + job, {
    headers: headers2,
  });
};
const allFinalAddJob = (values, gropNo, osr_id, jobNo, jobStatus, siteId) => {
  return axios.post(
    API_URL + "add-job-number-new",
    {
      start_date: moment(values.redSdate).format("MM/DD/YYYY"),
      end_date: moment(values.redEdate).format("MM/DD/YYYY"),
      AdminId: osr_id,
      jobNo: jobNo,
      siteid: siteId,
      last_cards: values.lastCard,
      send_email: values.emailStatus,
      status: jobStatus,
      groupNo: gropNo,
      site: API_ORIGIN?.replace("https://", ""),
      card:
        values.card1 +
        "," +
        values.card2 +
        "," +
        values.card3 +
        "," +
        values.card4 +
        "," +
        values.card5 +
        "," +
        values.card6 +
        "," +
        values.card7 +
        "," +
        values.card8 +
        "," +
        values.card9 +
        "," +
        values.card10 +
        "," +
        values.card11 +
        "," +
        values.card12,
    },

    {
      headers: headers,
    }
  );
};

const listRegisteredUsers = (limit, pageNo) => {
  return axios.get(
    API_URL + `view-register-data?page=${pageNo}&limit=${limit}`,

    {
      headers: headers2,
    }
  );
};
const searchRegisteredData = (limit, page, values) => {
  return axios.get(
    API_URL +
      `custom-register-search?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const exportRegisterUsers = (values) => {
  return axios.get(
    API_URL +
      `export-register-data${
        values?.fieldtype && values?.searchval
          ? "?" + values.fieldtype + "=" + values.searchval
          : ""
      }`,

    {
      headers: headers2,
    }
  );
};
const AdminListService = {
  updateCustomerCheckDuplicateVoucher,
  updateCustomerNew,
  allFinalAddJob,
  showLastUploadCardsForJob,
  getCustomersDataByIdNew,
  deleteCustomersDataNew,
  updateAccountantCheckDuplicateVoucher,
  checkDuplicateVoucher,
  updateOsrCheckDuplicateVoucher,
  addCustomerNew,
  profileData,
  profileUpdate,
  loginCustomer,
  loginAdmin,
  loginAccountant,
  loginPavlina,
  loginOsr,
  getStates,
  addCustomer,
  uploadCustomers,
  getCustomersData,
  updateCustomer,
  getCustomersDataById,
  deleteCustomersData,
  deleteCustomersDataAll,
  deleteSelectedCustomersData,
  checkCustomerEmail,
  updateCustomerEmail,
  getDataForSubmit,
  submitFinalCustomers,
  getOsrData,
  viewCustomerInModel,
  getOsrCustomerData,
  deleteOsrMain,
  deleteSingleOsrCustomer,
  updateCustomerOsr,
  checkOsrCustomerEmail,
  checkJobNumber,
  showLastUploadCards,
  lastUploadSave,
  getAdminCards,
  finalAddJob,
  addDateForExistJob,
  existJobRetain,
  jobSubmit,
  getOsrHistroyData,
  viewOsrHistroyInModel,
  mailledElectronically,
  cardAddForExistJob,
  getAccountantData,
  deleteAccountantMain,
  accountantSubmit,
  voucherClear,
  voucherUpdate,
  invoiceClear,
  invoiceUpdate,
  costUpdate,
  searchCertRecord,
  searchCustomRecord,
  allRecordChange,
  deActivateVoucher,

  checkJobData,
  multiJobSearch,
  toBeMailedCard,
  getCompanyList,
  mergeMailSearch,
  getForEdit,
  cardVirtual,
  updateCerti,
  listCustomer,
  deleteSiteSingle,
  siteCustomerAdd,
  listCustomerById,
  siteCustomerUpdate,
  listSubCompany,
  deleteSubCompany,
  listSubCompanyById,
  parentSites,
  subCompanyAdd,
  subCompanyUpdate,
  listPlasticManage,
  sitePlasticAdd,
  deleteSitePlastic,
  listPlasticById,
  sitePlasticUpdate,
  listOsr,
  osrUserAdd,
  deleteOsr,
  deletSelectedeOsr,
  listOsrById,
  osrUserUpdate,
  listAccountant,
  accountantUserAdd,
  deleteAcc,
  deletSelectedAcc,
  listAccountantById,
  accountantUserUpdate,
  listCsr,
  deleteCsr,
  deletSelectedCsr,
  csrUserAdd,
  listCsrById,
  csrUserUpdate,
  listPavlina,
  pavlinaAdminAdd,
  deletePav,
  deletSelectedPav,
  listPavById,
  pavlinaAdminUpdated,
  lockupTable,
  searchLockupTable,
  lockupRecordById,
  lockupUpdateById,

  listCard,
  listCardById,
  addCard,
  updateCard,
  searchCard,
  deletSelectedCard,
  listAdmin,
  deleteAdmin,
  deletSelectedAdmin,
  adminAdd,
  adminUpdated,
  listAdminById,
  listCardDetail,
  cardDetailByStatusCompany,
  listMails,
  userForMail,
  sendMailById,
  sendMailToSelected,
  sendMailByGroup,
  listStates,
  listStatesById,
  addStates,
  updatedStates,
  deleteState,
  deletSelectedStates,
  listCardByCompany,
  listCardByJob,
  getCardByJobGroup,
  updateCardByJobGroup,
  getAllCard,
  jobList,
  jobDetail,
  jobAdd,
  jobUpdate,
  jobByGroup,
  deletJobByGroup,
  allSites,
  allSitesCertificatesStatus,
  siteStatusUpdate,
  updateRandomCertificates,
  listDeletedUser,
  deleteDeletedUser,
  certiSearch,
  listRegisteredUsers,
  searchRegisteredData,
  exportRegisterUsers,
};

export default AdminListService;
